import axios from 'axios';
import { TokenPair } from '../types';

/**
 * Сервис для управления токенами аутентификации
 */
export class TokenService {
  private static instance: TokenService;
  
  private constructor() {}
  
  public static getInstance(): TokenService {
    if (!TokenService.instance) {
      TokenService.instance = new TokenService();
    }
    return TokenService.instance;
  }

  /**
   * Получить токены из localStorage
   */
  public getTokens(): TokenPair {
    return {
      accessToken: localStorage.getItem('access_token'),
      refreshToken: localStorage.getItem('refresh_token')
    };
  }

  /**
   * Установить токены в localStorage
   */
  public setTokens(accessToken: string, refreshToken: string): void {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    this.setupAxiosAuth(accessToken);
  }

  /**
   * Обновить только access token
   */
  public setAccessToken(accessToken: string): void {
    localStorage.setItem('access_token', accessToken);
    this.setupAxiosAuth(accessToken);
  }

  /**
   * Очистить токены
   */
  public clearTokens(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete axios.defaults.headers.common['Authorization'];
  }

  /**
   * Настроить заголовок авторизации для axios
   */
  public setupAxiosAuth(token: string): void {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  /**
   * Обновить токен с сервера
   */
  public async refreshToken(): Promise<string | null> {
    try {
      const { refreshToken } = this.getTokens();
      
      if (!refreshToken) {
        throw new Error('Нет refresh токена');
      }

      const response = await axios.post('https://enddel.com/auth/refresh', {
        refresh_token: refreshToken
      }, {
        headers: { 'Authorization': '' }
      });

      const { access_token, refresh_token } = response.data;
      
      this.setTokens(access_token, refresh_token);
      
      return access_token;
    } catch (error) {
      console.error('Ошибка при обновлении токена:', error);
      return null;
    }
  }
}

export default TokenService.getInstance();