import { User } from '../types';

declare global {
  interface Window {
    $crisp: any[];
  }
}

/**
 * Сервис для работы с Crisp чатом
 */
export class CrispService {
  private static instance: CrispService;
  
  private constructor() {}
  
  public static getInstance(): CrispService {
    if (!CrispService.instance) {
      CrispService.instance = new CrispService();
    }
    return CrispService.instance;
  }

  /**
   * Инициализировать Crisp для гостевых пользователей
   */
  public setupGuestUser(): void {
    if (!window.$crisp) return;
    
    // Устанавливаем базовую сессию для гостя
    window.$crisp.push(["set", "user:nickname", ["Гость"]]);
    
    // Обеспечить видимость чата для всех пользователей
    window.$crisp.push(["do", "chat:show"]);
    
    // Добавление CSRF токена для гостевых сессий
    const csrfToken = this.getCSRFToken();
    if (csrfToken) {
      window.$crisp.push(["set", "session:data", [[["csrf_token", csrfToken]]]]);
    }
  }

  /**
   * Настроить данные пользователя в Crisp
   */
  public setupUser(user: User): void {
    if (!window.$crisp) return;
    
    if (user.email) {
      window.$crisp.push(["set", "user:email", [user.email]]);
    }
    
    window.$crisp.push(["set", "user:nickname", [`${user.first_name} ${user.last_name}`]]);
    
    if (user.phone) {
      window.$crisp.push(["set", "user:phone", [user.phone]]);
    }
    
    window.$crisp.push(["set", "session:data", [[["user_id", user.id.toString()]]]]);
    
    // Добавление CSRF токена для авторизованных пользователей
    const csrfToken = this.getCSRFToken();
    if (csrfToken) {
      window.$crisp.push(["set", "session:data", [[["csrf_token", csrfToken]]]]);
    }
  }

  /**
   * Сбросить данные сессии Crisp
   */
  public resetSession(): void {
    if (window.$crisp) {
      window.$crisp.push(["do", "session:reset", []]);
      // После сброса настраиваем гостевой режим
      this.setupGuestUser();
    }
  }

  /**
   * Получить CSRF токен из cookie
   */
  private getCSRFToken(): string | null {
    const cookies = document.cookie.split(';').reduce((result, item) => {
      const cookie = item.trim().split('=');
      result[cookie[0]] = cookie[1];
      return result;
    }, {} as Record<string, string>);
    
    return cookies['XSRF-TOKEN'] || null;
  }
}

export default CrispService.getInstance();