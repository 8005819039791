// src/store/useProductStore.ts
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { queryClient } from '../queryClient';
// Исправляем импорты - выносим React hooks наружу
import { useEffect, useCallback, useRef } from 'react';

export type Product = {
  id: number;
  name: {
    en: string;
    ru: string;
    geo: string;
  };
  price: number;
  image_url: string | null;
  unit: string;
  step?: number;
  discounts?: { quantity: number; price: number }[];
  category_id?: number | null;
  stock_quantity?: number;
  slug?: string;
  // Добавляем свойства питательной ценности, которые есть в базе данных
  calories?: number;
  proteins?: number;
  fats?: number;
  carbs?: number;
};

type ProductStore = {
  // States
  products: Product[];
  stockUpdates: Record<number, number>; // Stores real-time stock updates
  lastUpdateTimestamps: Record<number, number>; // Tracks when updates occurred
  listeners: Record<number, number>; // Tracks components listening to stock updates
  
  // Actions
  setProducts: (products: Product[]) => void;
  updateProduct: (id: number, data: Partial<Product>) => void;
  addProduct: (product: Product) => void;
  removeProduct: (id: number) => void;
  updateProductStock: (productId: number, stockQuantity: number, timestamp?: number) => void;
  registerStockListener: (productId: number) => void;
  unregisterStockListener: (productId: number) => void;
  
  // Selectors
  getProductById: (id: number) => Product | undefined;
  getProductsByCategory: (categoryId: number | null) => Product[];
  getProductStock: (productId: number, defaultValue?: number) => number | undefined;
  getActiveListeners: () => number[];
};

export const useProductStore = create<ProductStore>()(
  persist(
    (set, get) => ({
      // Initial states
      products: [],
      stockUpdates: {},
      lastUpdateTimestamps: {},
      listeners: {},
      
      // Actions
      setProducts: (products: Product[]) => set({ products }),
      
      updateProduct: (id: number, data: Partial<Product>) => {
        set(state => ({
          products: state.products.map(product => 
            product.id === id ? { ...product, ...data } : product
          )
        }));
      },
      
      addProduct: (product: Product) => {
        set(state => ({
          products: [...state.products, product]
        }));
      },
      
      removeProduct: (id: number) => {
        set(state => ({
          products: state.products.filter(product => product.id !== id)
        }));
      },
      
      // Enhanced action for updating stock quantities with timestamp tracking
      updateProductStock: (productId: number, stockQuantity: number, timestamp = Date.now()) => {
        const state = get();
        const lastTimestamp = state.lastUpdateTimestamps[productId] || 0;
        
        // Only update if this is a newer update (prevents out-of-order updates)
        if (timestamp >= lastTimestamp) {
          console.log(`Updating stock for product ${productId} to ${stockQuantity} units at ${new Date(timestamp).toLocaleTimeString()}`);
          
          // Update stock updates and timestamps
          set(state => ({
            stockUpdates: {
              ...state.stockUpdates,
              [productId]: stockQuantity
            },
            lastUpdateTimestamps: {
              ...state.lastUpdateTimestamps,
              [productId]: timestamp
            }
          }));
          
          // Also update the product in the main list if it exists
          const product = state.products.find(p => p.id === productId);
          if (product) {
            set(state => ({
              products: state.products.map(p => 
                p.id === productId 
                  ? { ...p, stock_quantity: stockQuantity } 
                  : p
              )
            }));
          }
          
          // Update React Query cache if available
          try {
            // Update cache for product lists
            const cachedProducts = queryClient.getQueryData(['products']);
            if (cachedProducts) {
              queryClient.setQueryData(['products'], (oldData: any) => {
                if (Array.isArray(oldData)) {
                  return oldData.map(product => 
                    product.id === productId 
                      ? { ...product, stock_quantity: stockQuantity } 
                      : product
                  );
                } else if (oldData && oldData.products) {
                  return {
                    ...oldData,
                    products: oldData.products.map((product: any) => 
                      product.id === productId 
                        ? { ...product, stock_quantity: stockQuantity } 
                        : product
                    )
                  };
                }
                return oldData;
              });
            }
            
            // Update cache for individual product
            queryClient.setQueryData(['product', productId], (oldData: any) => {
              if (!oldData) return oldData;
              return { ...oldData, stock_quantity: stockQuantity };
            });
            
          } catch (error) {
            console.error('Error updating React Query cache:', error);
          }
          
          // Визуальное выделение обновленного элемента в DOM
          setTimeout(() => {
            try {
              const productElement = document.getElementById(`product-item-${productId}`);
              if (productElement) {
                productElement.style.transition = 'background-color 0.5s ease';
                productElement.style.backgroundColor = '#f0f9ff';
                
                setTimeout(() => {
                  if (productElement) {
                    productElement.style.backgroundColor = '';
                  }
                }, 2000);
              }
            } catch (e) {
              // Игнорируем ошибки DOM, так как это не критично
            }
          }, 100);
        } else {
          console.warn(`Ignoring outdated stock update for product ${productId}`);
        }
      },
      
      // Register a component as interested in stock updates for a product
      registerStockListener: (productId: number) => {
        set(state => ({
          listeners: {
            ...state.listeners,
            [productId]: (state.listeners[productId] || 0) + 1
          }
        }));
      },
      
      // Unregister a component when it unmounts
      unregisterStockListener: (productId: number) => {
        set(state => ({
          listeners: {
            ...state.listeners,
            [productId]: Math.max(0, (state.listeners[productId] || 1) - 1)
          }
        }));
      },
      
      // Selectors
      getProductById: (id: number) => {
        return get().products.find(product => product.id === id);
      },
      
      getProductsByCategory: (categoryId: number | null) => {
        return get().products.filter(product => product.category_id === categoryId);
      },
      
      // Enhanced selector that prioritizes real-time stock updates with freshness check
      getProductStock: (productId: number, defaultValue?: number) => {
        const { stockUpdates, products, lastUpdateTimestamps } = get();
        
        // Проверяем возраст обновления - если оно старше 10 минут, игнорируем его
        const now = Date.now();
        const lastUpdate = lastUpdateTimestamps[productId] || 0;
        const isStockUpdateFresh = (now - lastUpdate) < 10 * 60 * 1000; // 10 минут в миллисекундах
        
        // Используем обновление из stockUpdates только если оно свежее
        if (productId in stockUpdates && isStockUpdateFresh) {
          return stockUpdates[productId];
        }
        
        // Then check in the product list
        const product = products.find(p => p.id === productId);
        if (product && typeof product.stock_quantity !== 'undefined') {
          return product.stock_quantity;
        }
        
        // Finally fall back to default value
        return defaultValue;
      },
      
      // Get list of product IDs that have active listeners
      getActiveListeners: () => {
        const { listeners } = get();
        return Object.entries(listeners)
          .filter(([_, count]) => count > 0)
          .map(([id]) => parseInt(id, 10));
      },
    }),
    {
      name: 'product-storage',
      partialize: (state) => ({ 
        products: state.products,
        stockUpdates: state.stockUpdates,
        lastUpdateTimestamps: state.lastUpdateTimestamps,
      }),
    }
  )
);

// Добавляем новую функцию для очистки устаревших данных о запасах
export const resetStockUpdates = () => {
  const state = useProductStore.getState();
  useProductStore.setState({
    ...state,
    stockUpdates: {},
    lastUpdateTimestamps: {}
  });
  
  // Можно также удалить только эти разделы из localStorage напрямую
  try {
    const storageKey = 'product-storage';
    const storageData = localStorage.getItem(storageKey);
    if (storageData) {
      const parsedData = JSON.parse(storageData);
      const newData = {
        ...parsedData,
        state: {
          ...parsedData.state,
          stockUpdates: {},
          lastUpdateTimestamps: {}
        }
      };
      localStorage.setItem(storageKey, JSON.stringify(newData));
    }
  } catch (e) {
    console.error('Ошибка при очистке локального хранилища:', e);
  }
};

// ПОЛНОСТЬЮ ПЕРЕРАБОТАННЫЙ ХУК - устраняет цикл рендеринга
export function useGetProductStock() {
  // Получаем функции из хранилища
  const getStock = useProductStore(state => state.getProductStock);
  const registerListener = useProductStore(state => state.registerStockListener);
  const unregisterListener = useProductStore(state => state.unregisterStockListener);
  
  // Создаем ref для хранения списка зарегистрированных ID товаров
  const registeredIds = useRef<Set<number>>(new Set());
  
  // Функция для безопасного получения значения запаса
  const getStockSafe = useCallback((productId: number, defaultValue?: number) => {
    // Регистрируем слушателя только если этот ID еще не зарегистрирован
    if (!registeredIds.current.has(productId)) {
      registerListener(productId);
      registeredIds.current.add(productId);
    }
    
    // Получаем текущее значение запаса
    return getStock(productId, defaultValue);
  }, [getStock, registerListener]);
  
  // Очистка при размонтировании
  useEffect(() => {
    // При размонтировании отменяем регистрацию всех слушателей
    return () => {
      registeredIds.current.forEach(id => {
        unregisterListener(id);
      });
    };
  }, [unregisterListener]);
  
  return getStockSafe;
}

export const useProducts = () => useProductStore(state => state.products);
export const useSetProducts = () => useProductStore(state => state.setProducts);
export const useGetProductById = () => useProductStore(state => state.getProductById);
export const useUpdateProductStock = () => useProductStore(state => state.updateProductStock);

// Export updateProductStock function for use in socket.js
export const updateProductStock = (productId: number, stockQuantity: number, timestamp = Date.now()) => {
  return useProductStore.getState().updateProductStock(productId, stockQuantity, timestamp);
};