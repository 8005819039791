import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Собственный "бэкенд" для ленивая загрузки JSON-файлов переводов
const DynamicImportBackend = {
  type: 'backend' as const, // Можно указать 'backend' или оставить без 'as const'
  read: async (language: string, namespace: string, callback: any) => {
    try {
      // Импортируем JSON конкретного языка и неймспейса (обычно 'translation')
      const resources = await import(
        /* webpackChunkName: "i18n-[request]" */
        `../public/locales/${language}/${namespace}.json`
      );
      callback(null, resources);
    } catch (error) {
      callback(error, false);
    }
  },
};

i18n
  .use(LanguageDetector)
  .use(DynamicImportBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, 
    },
  });

// Добавляем обработчик события изменения языка для сохранения выбора в localStorage
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('i18nextLng', lng);
});

export default i18n;