// src/styles/Theme.tsx
export const theme = {
  colors: {
    // Main palette
    primaryBg: "#E5E5E5",
    mainBg: "#FFFFFF",
    ShopWindowBg: "#F0F4F8",
    accent: "#0098EA",
    font: "#181818",
    whiteFont: "#FFFFFF",
    
    // Map component colors (merged)
    map: {
      primary: "#3563E9",
      primaryLight: "#E8EFFF",
      primaryDark: "#2046B8",
      secondary: "#F0F4FF",
      error: "#FF4D4F",
      errorLight: "#FFEDED",
      background: "#FFFFFF",
      cardBackground: "#FFFFFF",
      text: "#1A1D1F",
      textSecondary: "#6F767E",
      textLight: "#9A9FA5",
      border: "#E6E8EC",
      borderHover: "#D4D6D9",
      inputBg: "#F4F5F6",
      success: "#3DD598",
      successLight: "#EAFAF4",
    }
  },
  media: {
    tablet: "screen and (max-width:768px)",
    mobile: "screen and (max-width:480px)",
  },
  button: {
    buttonActive: "#0098EA",
    buttonHover: "#0098eac4",
    buttonDisabled: "#808080",
    errorbtn: "#dd2c2c"
  },
  shadows: {
    sm: '0px 2px 8px rgba(20, 23, 38, 0.08)',
    md: '0px 4px 16px rgba(20, 23, 38, 0.12)',
    lg: '0px 8px 30px rgba(20, 23, 38, 0.14)',
  },
  animations: {
    fadeIn: 'fadeIn 0.3s ease',
    slideUp: 'slideUp 0.2s ease',
    spin: 'spin 1s linear infinite',
  },
  inputs: {
    focus: `
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 152, 234, 0.3);
    `,
    standard: `
      width: 100%;
      padding: 16px;
      font-size: 16px;
      border-radius: 12px;
      border: 1px solid #E6E8EC;
      background-color: #F4F5F6;
      color: #1A1D1F;
      transition: all 0.2s ease;
      
      &:hover {
        border-color: #D4D6D9;
      }
      
      &:focus {
        outline: none;
        border-color: #0098EA;
        box-shadow: 0 0 0 3px rgba(0, 152, 234, 0.2);
        background-color: #FFFFFF;
      }
      
      &::placeholder {
        color: #9A9FA5;
      }
    `
  },
  borderRadius: {
    sm: '8px',
    md: '12px',
    lg: '16px',
    round: '50%',
  }
};

// Define keyframe animations
export const globalAnimations = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes slideUp {
    from { transform: translateY(10px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;