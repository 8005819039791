// src/page/Administarion/AdminProduct/utils/useNotifications.ts

import { useState, useEffect, useCallback } from 'react';
import { create } from 'zustand';

// Типы уведомлений
export type NotificationType = 'update' | 'out_of_stock' | 'back_in_stock' | 'success' | 'error' | 'warning' | 'info';

// Интерфейс для уведомления
export interface Notification {
  id: number;
  message: string;
  type: NotificationType;
  timestamp: number;
  duration?: number;
}

// Создаем глобальное хранилище для уведомлений
interface NotificationStore {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id' | 'timestamp'>) => void;
  removeNotification: (id: number) => void;
}

// Создаем Zustand store для глобального доступа к уведомлениям
export const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  addNotification: (notification) => {
    const newNotification = {
      ...notification,
      id: Date.now() + Math.random(),
      timestamp: Date.now(),
    };
    
    set((state) => ({
      notifications: [...state.notifications, newNotification],
    }));
    
    // Автоматическое удаление уведомления через заданное время
    setTimeout(() => {
      set((state) => ({
        notifications: state.notifications.filter(
          (n) => n.id !== newNotification.id
        ),
      }));
    }, notification.duration || 5000);
  },
  removeNotification: (id) => {
    set((state) => ({
      notifications: state.notifications.filter((n) => n.id !== id),
    }));
  },
}));

// Функция для глобального доступа к добавлению уведомлений вне React-компонентов
export const showNotification = (message: string, type: NotificationType = 'info', duration?: number) => {
  useNotificationStore.getState().addNotification({ message, type, duration });
};

// Хук для использования в React-компонентах
export const useNotifications = () => {
  const { notifications, addNotification, removeNotification } = useNotificationStore();
  
  // Утилитарные функции для разных типов уведомлений
  const addSuccessNotification = useCallback(
    (message: string, duration?: number) => {
      addNotification({ message, type: 'success', duration });
    },
    [addNotification]
  );
  
  const addErrorNotification = useCallback(
    (message: string, duration?: number) => {
      addNotification({ message, type: 'error', duration });
    },
    [addNotification]
  );
  
  const addWarningNotification = useCallback(
    (message: string, duration?: number) => {
      addNotification({ message, type: 'warning', duration });
    },
    [addNotification]
  );
  
  const addInfoNotification = useCallback(
    (message: string, duration?: number) => {
      addNotification({ message, type: 'info', duration });
    },
    [addNotification]
  );

  const addStockNotification = useCallback(
    (message: string, type: NotificationType = 'update', duration?: number) => {
      addNotification({ message, type, duration });
    },
    [addNotification]
  );
  
  return {
    notifications,
    addNotification,
    removeNotification,
    addSuccessNotification,
    addErrorNotification,
    addWarningNotification,
    addInfoNotification,
    addStockNotification
  };
};

// Глобальная функция для показа уведомлений из не-React кода (например, socket.js)
export const notifyGlobally = (message: string, type: NotificationType = 'info', duration?: number) => {
  showNotification(message, type, duration);
};

export default useNotifications;