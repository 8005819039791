import { User } from '../types';

/**
 * Сервис для хранения и получения данных пользователя
 */
export class UserStorageService {
  private static instance: UserStorageService;
  
  private constructor() {}
  
  public static getInstance(): UserStorageService {
    if (!UserStorageService.instance) {
      UserStorageService.instance = new UserStorageService();
    }
    return UserStorageService.instance;
  }

  private readonly USER_KEY = 'user';

  /**
   * Получить данные пользователя из localStorage
   */
  public getUser(): User | null {
    try {
      const userJson = localStorage.getItem(this.USER_KEY);
      return userJson ? JSON.parse(userJson) : null;
    } catch (error) {
      console.error('Ошибка при получении данных пользователя:', error);
      return null;
    }
  }

  /**
   * Сохранить данные пользователя в localStorage
   */
  public setUser(user: User): void {
    try {
      localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    } catch (error) {
      console.error('Ошибка при сохранении данных пользователя:', error);
    }
  }

  /**
   * Удалить данные пользователя из localStorage
   */
  public removeUser(): void {
    localStorage.removeItem(this.USER_KEY);
  }
}

export default UserStorageService.getInstance();