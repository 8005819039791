// src/hooks/useCrisp.ts
import { useEffect } from 'react';

export function useCrisp(crispWebsiteId: string) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Инициализируем Crisp
      (window as any).$crisp = [];
      (window as any).CRISP_WEBSITE_ID = crispWebsiteId;

      // Функция для получения CSRF токена из cookie
      const getCSRFToken = () => {
        const cookies = document.cookie.split(';').reduce((result, item) => {
          const cookie = item.trim().split('=');
          result[cookie[0]] = cookie[1];
          return result;
        }, {} as Record<string, string>);
        
        return cookies['XSRF-TOKEN'];
      };

      // Добавляем обработчик события для добавления CSRF токена к запросам Crisp
      (window as any).$crisp.push(["on", "socket:connected", function() {
        const csrfToken = getCSRFToken();
        if (csrfToken) {
          // Добавляем CSRF токен в сессию Crisp
          (window as any).$crisp.push(["set", "session:data", [[
            ["csrf_token", csrfToken]
          ]]]);
        }
      }]);

      // Добавляем обработчик для отображения чата для всех пользователей
      (window as any).$crisp.push(["on", "session:loaded", function() {
        // Гарантируем, что чат отображается для всех пользователей
        (window as any).$crisp.push(["do", "chat:show"]);
      }]);

      // Загрузка скрипта Crisp
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.head.appendChild(s);
    }
  }, [crispWebsiteId]);
}