// src/layout/basket/CartContext.tsx

import React, { createContext, useContext, useMemo } from 'react';
import { CartItem, CartContextType } from './basketTypes';

/**
 * This is an adaptation layer that bridges Zustand to the existing Context API.
 * Import actual store functions directly when needed to prevent circular dependencies.
 */

// Import bare minimum to avoid circular references
import { useCartStore } from '../../store/useCartStore';

// Create the context with a proper type
const CartContext = createContext<CartContextType | null>(null);

/**
 * Cart Provider Component
 * Acts as an adapter between Zustand store and React's Context API
 */
export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Get state and actions from Zustand, but DON'T use the custom hooks that may cause circular deps
  const cartItems = useCartStore(state => state.items);
  const addItem = useCartStore(state => state.addItem);
  const updateItem = useCartStore(state => state.updateItem);
  const removeItem = useCartStore(state => state.removeItem);
  const clearCart = useCartStore(state => state.clearCart);
  
  // Create a stable API object that won't change on every render
  const value = useMemo(() => ({
    cartItems,
    addItemToCart: (item: CartItem) => addItem(item),
    updateItemInCart: (item: CartItem) => updateItem(item),
    removeItemFromCart: (id: number) => removeItem(id),
    clearCart: () => clearCart()
  }), [cartItems, addItem, updateItem, removeItem, clearCart]);
  
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

/**
 * Custom hook to use cart functionality
 * This adapter allows easy migration from Context to Zustand
 */
export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (context === null) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};