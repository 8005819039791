// src/queryClient.ts
import { QueryClient } from '@tanstack/react-query';

// Создаем экземпляр QueryClient для использования вне React-компонентов
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 минут
      gcTime: 1000 * 60 * 15, // 15 минут
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});