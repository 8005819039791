// src/components/ErrorBoundary.tsx
import React, { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

const ErrorContainer = styled.div`
  margin: 20px;
  padding: 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  background-color: #f8d7da;
  color: #721c24;
`;

const ErrorTitle = styled.h2`
  margin-top: 0;
  font-size: 20px;
`;

const ErrorDetails = styled.details`
  margin-top: 10px;
  white-space: pre-wrap;
`;

const ReloadButton = styled.button`
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
`;

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    // Можно также отправить ошибку в сервис отчетов об ошибках
  }

  private handleReload = () => {
    window.location.reload();
  };

  public render() {
    if (this.state.hasError) {
      // Можно отобразить любой пользовательский UI при ошибке
      if (this.props.fallback) {
        return this.props.fallback;
      }
      
      return (
        <ErrorContainer>
          <ErrorTitle>Что-то пошло не так</ErrorTitle>
          <p>Приносим извинения, но при рендеринге этого компонента произошла ошибка.</p>
          
          {this.state.error && (
            <ErrorDetails>
              <summary>Посмотреть детали ошибки</summary>
              <pre>{this.state.error.toString()}</pre>
            </ErrorDetails>
          )}
          
          <ReloadButton onClick={this.handleReload}>
            Перезагрузить страницу
          </ReloadButton>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;