// src/store/useCartStore.ts
import { create } from 'zustand';
import { persist, createJSONStorage, StateStorage } from 'zustand/middleware';
import { CartItem, Discount } from '../layout/basket/basketTypes';

// Define allowed language keys
type LanguageKey = 'en' | 'ru' | 'geo';

type CartState = {
  // State
  items: CartItem[];
  cartExpiryTime: number | null;  
  
  // Actions
  addItem: (item: CartItem) => void;
  updateItem: (item: CartItem) => void;
  removeItem: (id: number) => void;
  clearCart: () => void;
  updateTitles: (language: string) => void;
  extendCartExpiry: () => void;  // Action to extend cart expiry time
  
  // Selectors
  getCartTotal: () => number;
  getCartItemCount: () => number;
  getCartItemById: (id: number, fromRecipe?: boolean) => CartItem | undefined;
  getIsCartExpiringSoon: () => boolean;  // Check if cart expires soon
  getCartExpiryTimeRemaining: () => number;  // Get remaining time in ms
};

// Helper function to calculate item price with discounts
const calculateTotalPrice = (
  basePrice: number,
  quantity: number,
  unit: string,
  step: number = 1,
  discounts?: Discount[]
): number => {
  let applicablePrice = basePrice;

  if (discounts && discounts.length > 0) {
    const eligibleDiscounts = discounts.filter(discount => quantity >= discount.quantity);
    if (eligibleDiscounts.length > 0) {
      const bestDiscount = eligibleDiscounts.reduce((prev, curr) =>
        curr.price < prev.price ? curr : prev
      );
      applicablePrice = bestDiscount.price;
    }
  }

  return unit === 'g' ? applicablePrice * (quantity / step) : applicablePrice * quantity;
};

// Create custom storage with better expiration handling
const CART_EXPIRY_TIME = 4 * 60 * 60 * 1000; // 4 часа в миллисекундах

const customStorage: StateStorage = {
  getItem: (name): string | null => {
    const savedData = localStorage.getItem(name);
    if (savedData) {
      try {
        const { state, timestamp } = JSON.parse(savedData);
        
        // Check if cart has expired
        if (Date.now() - timestamp < CART_EXPIRY_TIME) {
          return JSON.stringify({
            ...state,
            cartExpiryTime: timestamp + CART_EXPIRY_TIME
          });
        } else {
          localStorage.removeItem(name);
          return null;
        }
      } catch (e) {
        console.error('Error parsing cart data:', e);
        localStorage.removeItem(name);
        return null;
      }
    }
    return null;
  },
  
  setItem: (name, value): void => {
    try {
      const state = JSON.parse(value);
      const cartData = {
        state,
        timestamp: Date.now(),
      };
      localStorage.setItem(name, JSON.stringify(cartData));
    } catch (e) {
      console.error('Error saving cart data:', e);
    }
  },
  
  removeItem: (name): void => {
    localStorage.removeItem(name);
  }
};

// Helper function to get valid language key
const getLanguageKey = (language: string): LanguageKey => {
  if (language === 'en' || language === 'ru' || language === 'geo') {
    return language;
  }
  return 'en'; // Default to English
};

// Create the Zustand store
export const useCartStore = create<CartState>()(
  persist(
    (set, get) => ({
      // Initial state
      items: [],
      cartExpiryTime: null,
      
      // Actions
      addItem: (item: CartItem) => {
        set((state) => {
          const newItems = [...state.items];
          const now = Date.now();
          const expiryTime = now + CART_EXPIRY_TIME;
          
          // Ищем товар с таким же ID и источником рецепта
          const existingItemIndex = newItems.findIndex(existingItem => {
            if (existingItem.id !== item.id) {
              return false;
            }
            
            // Проверка источника рецепта
            const existingRecipeId = existingItem.recipeSource?.id;
            const newRecipeId = item.recipeSource?.id;
            
            // Если оба null - совпадают (оба обычных товара)
            if (!existingRecipeId && !newRecipeId) {
              return true;
            }
            
            // Если один null, а другой нет - не совпадают (один из рецепта, другой обычный)
            if (!existingRecipeId || !newRecipeId) {
              return false;
            }
            
            // Если оба есть - сравниваем ID рецептов
            return existingRecipeId === newRecipeId;
          });
          
          if (existingItemIndex !== -1) {
            // Обновляем существующий товар
            newItems[existingItemIndex] = {
              ...newItems[existingItemIndex],
              quantity: newItems[existingItemIndex].quantity + item.quantity
            };
          } else {
            // Добавляем новый товар
            newItems.push(item);
          }
          
          return {
            items: newItems,
            cartExpiryTime: expiryTime
          };
        });
      },
      
      updateItem: (item: CartItem) => set((state) => {
        const index = state.items.findIndex(existingItem => {
          if (existingItem.id !== item.id) {
            return false;
          }
          
          // Проверка источника рецепта
          const existingRecipeId = existingItem.recipeSource?.id;
          const newRecipeId = item.recipeSource?.id;
          
          // Если оба null - совпадают
          if (!existingRecipeId && !newRecipeId) {
            return true;
          }
          
          // Если один null, а другой нет - не совпадают
          if (!existingRecipeId || !newRecipeId) {
            return false;
          }
          
          // Если оба есть - сравниваем ID
          return existingRecipeId === newRecipeId;
        });
        
        if (index === -1) {
          return state;
        }
        
        const newItems = [...state.items];
        newItems[index] = {
          ...newItems[index],
          quantity: item.quantity
        };
        
        return {
          items: newItems,
          cartExpiryTime: Date.now() + CART_EXPIRY_TIME
        };
      }),
      
      removeItem: (id: number) => set((state) => ({
        items: state.items.filter((i) => i.id !== id)
      })),
      
      clearCart: () => set({ 
        items: [],
        cartExpiryTime: null
      }),
      
      // Update titles when language changes
      updateTitles: (language: string) => {
        const langKey = getLanguageKey(language);
        
        set((state) => ({
          items: state.items.map(item => ({
            ...item,
            title: item.titles[langKey] || item.titles.en || item.title
          }))
        }));
      },
      
      // Extend cart expiry
      extendCartExpiry: () => set((state) => ({
        cartExpiryTime: Date.now() + CART_EXPIRY_TIME
      })),
      
      // Selectors
      getCartTotal: () => {
        const { items } = get();
        return items.reduce((sum, item) => {
          return (
            sum +
            calculateTotalPrice(
              item.price,
              item.quantity,
              item.unit,
              item.step || 1,
              item.discounts
            )
          );
        }, 0);
      },
      
      getCartItemCount: () => {
        return get().items.length;
      },
      
      // Обновленный селектор для получения товара по ID с учетом источника
      getCartItemById: (id: number, fromRecipe?: boolean) => {
        const items = get().items;
        if (fromRecipe === undefined) {
          // Если не указано, просто возвращаем первый найденный товар с этим ID
          return items.find((item) => item.id === id);
        } else if (fromRecipe) {
          // Ищем товар с таким ID, который точно из рецепта
          return items.find((item) => item.id === id && !!item.recipeSource);
        } else {
          // Ищем товар с таким ID, который точно НЕ из рецепта
          return items.find((item) => item.id === id && !item.recipeSource);
        }
      },
      
      getIsCartExpiringSoon: () => {
        const { cartExpiryTime } = get();
        if (!cartExpiryTime) return false;
        
        // Return true if less than 30 minutes remain
        const timeRemaining = cartExpiryTime - Date.now();
        return timeRemaining > 0 && timeRemaining < 30 * 60 * 1000;
      },
      
      getCartExpiryTimeRemaining: () => {
        const { cartExpiryTime } = get();
        if (!cartExpiryTime) return 0;
        
        const timeRemaining = cartExpiryTime - Date.now();
        return Math.max(0, timeRemaining);
      }
    }),
    {
      name: 'cart-storage',
      partialize: (state) => ({ 
        items: state.items,
        cartExpiryTime: state.cartExpiryTime
      }),
      storage: createJSONStorage(() => customStorage),
    }
  )
);

// Simplified helper hooks that directly work with the store
export const useCartItems = () => useCartStore(state => state.items);
// Обновленный хук с поддержкой параметра fromRecipe
export const useCartItemById = (id: number, fromRecipe?: boolean) => 
  useCartStore(state => state.getCartItemById(id, fromRecipe));
export const useIsCartExpiringSoon = () => useCartStore(state => state.getIsCartExpiringSoon());
export const useCartExpiryTimeRemaining = () => useCartStore(state => state.getCartExpiryTimeRemaining());

export const useCartActions = () => {
  const store = useCartStore();
  
  return {
    addItemToCart: (item: CartItem) => store.addItem(item),
    updateItemInCart: (item: CartItem) => store.updateItem(item),
    removeItemFromCart: (id: number) => store.removeItem(id),
    clearCart: () => store.clearCart(),
    extendCartExpiry: () => store.extendCartExpiry()
  };
};

export const useCartTotal = () => useCartStore(state => state.getCartTotal());
export const useCartItemCount = () => useCartStore(state => state.getCartItemCount());