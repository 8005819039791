import { createGlobalStyle } from "styled-components";
import { theme, globalAnimations } from "./Theme";

interface GlobalStyledProps {
  isOpen: boolean;
}

export const GlobalStyled = createGlobalStyle<GlobalStyledProps>`
  ${globalAnimations}
  
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: "inter-variable", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${theme.colors.font};
    line-height: 1.2;
    background-color: ${theme.colors.primaryBg};
    overflow: ${(props) => (props.isOpen ? 'hidden' : 'auto')};
  }

  /* Специальные стили для iOS скроллинга */
  body.scroll-locked {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
  }

  .scrollable {
    -webkit-overflow-scrolling: touch !important;
    overflow-y: auto !important;
    height: 100% !important;
    overscroll-behavior: contain !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.font};
  }

  ul {
    list-style: none;
  }

  button {
    background-color: unset;
    border: none;
    cursor: pointer;
    color: ${theme.colors.font};
  }

  section:nth-of-type(odd) {
    background-color: ${theme.colors.primaryBg};
  }

  section:nth-of-type(even) {
    background-color: ${theme.colors.mainBg};
  }

  span {
    font-size: 16px;
    @media ${theme.media.tablet} {
      font-size: 14px;
    }
  }

  h1 {
    font-variation-settings: "wght" 600;
    font-size: 24px;
    text-transform: uppercase;
    @media (max-width: 1024px) {
      font-size: 22px;
    }
    @media (max-width: 430px) {
      font-size: 18px;
    }
  }

  h2, h3 {
    font-variation-settings: "wght" 500;
    font-size: 20px;
    line-height: normal;
    letter-spacing: 1px;
    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }

  h3 {
    font-size: 16px;
    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }
  
  h4 {
    font-size: 16px; 
    margin-top: 2px;
    margin-bottom: 15px;
    font-weight: 400;
  }
  
  p {
    font-variation-settings: "wght" 400;
    font-size: 16px;
    @media (max-width: 430px) {
      font-size: 14px;
    }
    @media (max-width: 430px) {
      font-size: 12px;
    }
  }

  /* Standardized input styles from theme */
  input, textarea, select {
    font-family: "inter-variable", sans-serif;
  }

  /* Map-specific elements */
  .leaflet-container {
    font-family: "inter-variable", sans-serif;
  }
  
  .custom-tooltip {
    background-color: ${theme.button.buttonActive};
    border: none;
    border-radius: ${theme.borderRadius.sm};
    padding: 8px 12px;
    color: white;
    font-weight: 500;
    box-shadow: 0 4px 12px rgba(0, 152, 234, 0.3);
  }
  
  .custom-tooltip::before {
    border-top-color: ${theme.button.buttonActive};
  }
  
  .leaflet-control-zoom {
    border-radius: ${theme.borderRadius.sm};
    overflow: hidden;
    box-shadow: ${theme.shadows.sm};
  }
  
  .leaflet-control-zoom a {
    background-color: white;
    color: ${theme.colors.map.text};
    font-size: 16px;
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  
  .leaflet-control-zoom a:hover {
    background-color: ${theme.colors.map.inputBg};
    color: ${theme.button.buttonActive};
  }

  /* Spinner animation for loading indicators */
  .spin {
    animation: ${theme.animations.spin};
  }

  /* Accessibility improvement - remove blue mobile highlight */
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
  }
`;