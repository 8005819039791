import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { User } from '../types';
import AuthContext from '../context/AuthContext';
import { setupInterceptors } from '../services/axiosInterceptors';
import tokenService from '../services/tokenService';
import userStorage from '../services/userStorage';
import socketService from '../services/socketService';
import crispService from '../services/crispService';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Функция обновления токена, передаваемая в перехватчики
  const refreshAuthToken = useCallback(async (): Promise<string | null> => {
    return await tokenService.refreshToken();
  }, []);

  // Логин пользователя
  const login = useCallback((user: User, accessToken?: string, refreshToken?: string) => {
    setUser(user);
    setIsAuthenticated(true);
    userStorage.setUser(user);

    // Если токен предоставлен - устанавливаем его
    if (accessToken && refreshToken) {
      tokenService.setTokens(accessToken, refreshToken);
    } 
    // Если только access token
    else if (accessToken) {
      tokenService.setAccessToken(accessToken);
    }

    socketService.emitLogin(user.id);
    crispService.setupUser(user);
  }, []);

  // Выход пользователя
  const logout = useCallback(() => {
    if (user) {
      socketService.emitLogout(user.id);
    }
    
    setUser(null);
    setIsAuthenticated(false);
    
    userStorage.removeUser();
    tokenService.clearTokens();
    crispService.resetSession();
    
    navigate('/');
  }, [user, navigate]);

  // Настройка перехватчиков axios
  useEffect(() => {
    const { ejectInterceptors } = setupInterceptors(refreshAuthToken, logout);
    
    return () => {
      ejectInterceptors();
    };
  }, [refreshAuthToken, logout]);

  // Инициализация аутентификации при загрузке
  useEffect(() => {
    const initAuth = async () => {
      try {
        const storedUser = userStorage.getUser();
        const { accessToken, refreshToken } = tokenService.getTokens();

        if (storedUser && accessToken) {
          // Если есть пользователь и токен - восстанавливаем сессию
          setUser(storedUser);
          setIsAuthenticated(true);
          tokenService.setupAxiosAuth(accessToken);
          socketService.emitLogin(storedUser.id);
          crispService.setupUser(storedUser);
        } 
        else if (refreshToken) {
          // Если есть только refresh token - пробуем обновить
          const newToken = await refreshAuthToken();
          if (newToken && storedUser) {
            setUser(storedUser);
            setIsAuthenticated(true);
            socketService.emitLogin(storedUser.id);
            crispService.setupUser(storedUser);
          } else {
            // Если не получилось - очищаем всё
            cleanupAuth();
          }
        } 
        else {
          // Если нет ни пользователя, ни токенов - очищаем
          cleanupAuth();
        }
      } catch (err) {
        console.error('Ошибка при инициализации аутентификации:', err);
        cleanupAuth();
      } finally {
        setLoading(false);
      }
    };

    const cleanupAuth = () => {
      setUser(null);
      setIsAuthenticated(false);
      userStorage.removeUser();
      tokenService.clearTokens();
    };

    initAuth();
  }, [refreshAuthToken]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, refreshAuthToken, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};