// App.tsx
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider, useAuth } from './layout/authorization';
import ProtectedRoute from './layout/Protect/ProtectedRoute';
import { PurchasedItemsProvider } from './page/paymentsPages/PurchasedItemsContext';
import { UserProvider } from './components/telegram/UserContext';
import { useCrisp } from './components/hooks/useCrisp';
import ErrorBoundary from './components/ErrorBoundary';
import 'react-toastify/dist/ReactToastify.css';
import crispService from './layout/authorization/services/crispService';
import socket from './socket'; // Импортируем Socket.IO клиент

// Импортируем наш спиннер
import { Spinner } from './components/spiner/Spinner';

// Создаем клиент запросов
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 минут
      gcTime: 10 * 60 * 1000, // 10 минут кэширования
    },
  },
});

// Экспортируем queryClient для использования в других модулях
export { queryClient };

// Компонент для инициализации Crisp и Socket.IO
const ServicesInitializer = () => {
  useCrisp("63e2c9a9-fa2b-4c85-a14f-f0f7b0c0e313");
  const { user } = useAuth();

  useEffect(() => {
    // Настройка Crisp
    if (user) {
      crispService.setupUser(user);
    } else {
      crispService.setupGuestUser();
    }

    // Инициализация Socket.IO
    socket.connect();

    // Инициализация уведомлений
    if (typeof window !== 'undefined' && 'Notification' in window) {
      if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
        Notification.requestPermission();
      }
    }

    // Очистка при размонтировании
    return () => {
      socket.disconnect();
    };
  }, [user]);

  return null;
};

// Ленивая загрузка модулей (страниц):
const Shop = lazy(() => import('./page/shop'));
const AdminPanel = lazy(() => import('./page/AdminPanel'));
const LoginComponent = lazy(() => import('./layout/UserAuteriztion/UserCart'));
const PaymentSuccess = lazy(() => import('./page/paymentsPages/PaymentSuccess'));
const FlowTelegramAuth = lazy(() => import('./components/telegram/FlowTelegramAuth'));
const VerifyEmailComponent = lazy(() => import('./page/register/verification/VerifyEmailComponent'));

export function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <PurchasedItemsProvider>
            <UserProvider>
              <ServicesInitializer />
              <Suspense fallback={<Spinner />}>
                <FlowTelegramAuth />
                <Routes>
                  {/* Обновленные маршруты: добавляем опциональный параметр productSlug */}
                  <Route path="/" element={<Shop />} />
                  <Route path="/:productSlug" element={<Shop />} />
                  <Route path="/auth" element={<LoginComponent />} />
                  <Route path="/reset-password/:token" element={<LoginComponent />} />
                  {/* Новый маршрут для подтверждения email */}
                  <Route path="/verify-email/:token" element={<VerifyEmailComponent />} />
                  <Route
                    path="/admin"
                    element={
                      <ProtectedRoute
                        element={<AdminPanel />}
                        allowedRoles={['admin', 'courier']}
                      />
                    }
                  />
                  <Route path="/payment/success" element={<PaymentSuccess />} />
                </Routes>
              </Suspense>
            </UserProvider>
          </PurchasedItemsProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;