import socket from '../../../socket';
import { User } from '../types';

/**
 * Сервис для управления сокет-соединениями при аутентификации
 */
export class SocketService {
  private static instance: SocketService;
  
  private constructor() {}
  
  public static getInstance(): SocketService {
    if (!SocketService.instance) {
      SocketService.instance = new SocketService();
    }
    return SocketService.instance;
  }

  /**
   * Выполнить действия при входе пользователя в систему
   */
  public emitLogin(userId: number): void {
    socket.emit('login', userId);
  }

  /**
   * Выполнить действия при выходе пользователя
   */
  public emitLogout(userId: number): void {
    socket.emit('logout', userId);
  }
}

export default SocketService.getInstance();