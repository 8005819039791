import axios, { AxiosRequestConfig } from 'axios';
import tokenService from './tokenService';

/**
 * Настройка перехватчиков axios для автоматического обновления токенов
 * @param refreshCallback Функция обновления токена
 * @param logoutCallback Функция выхода из системы при истекшей сессии
 * @returns объект с методами для удаления перехватчиков
 */
export const setupInterceptors = (
  refreshCallback: () => Promise<string | null>,
  logoutCallback: () => void,
) => {
  // Перехватчик запросов для добавления токена
  const requestInterceptor = axios.interceptors.request.use(
    (config) => {
      const { accessToken } = tokenService.getTokens();
      if (accessToken) {
        config.headers = config.headers || {};
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Перехватчик ответов для обработки 401 ошибок
  const responseInterceptor = axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const newToken = await refreshCallback();
          if (newToken) {
            originalRequest.headers = originalRequest.headers || {};
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            return axios(originalRequest);
          } else {
            logoutCallback();
            return Promise.reject(new Error('Сессия истекла. Выполните вход снова.'));
          }
        } catch (refreshError) {
          logoutCallback();
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );

  // Возвращаем функцию для удаления перехватчиков при размонтировании компонента
  return {
    ejectInterceptors: () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    }
  };
};