// src/socket.js
import io from 'socket.io-client';
import { updateProductStock } from './store/useProductStore';
// Импортируем новую функцию для показа уведомлений
import { notifyGlobally } from './page/Administarion/AdminProduct/utils/useNotifications';

// Импортируем типы для TypeScript (будет использоваться только во время компиляции)
// Типы определены в src/socket-types.d.ts
/** @type {import('./socket-types').AppSocket} */
let socket = null;

// Функция для получения CSRF токена из куки
function getCSRFToken() {
  if (typeof document === 'undefined') return '';
  
  const cookies = document.cookie.split(';').reduce((result, item) => {
    const cookie = item.trim().split('=');
    result[cookie[0]] = cookie[1];
    return result;
  }, {});
  
  return cookies['XSRF-TOKEN'] || '';
}

// Проверяем поддержку сокетов в окружении
const isBrowser = typeof window !== 'undefined';

// Инициализируем socket только если он еще не был создан
export function initializeSocket() {
  if (isBrowser && !socket) {
    try {
      socket = io(window.location.origin, {
        withCredentials: true,
        extraHeaders: {
          'X-CSRF-Token': getCSRFToken()
        }
      });
      
      // Сохраняем socket в глобальном объекте для доступа из других компонентов
      window.socket = socket;
      
      // Настраиваем базовые обработчики событий
      setupSocketEventHandlers();
      
      return socket;
    } catch (e) {
      console.error('Error initializing socket:', e);
    }
  }
  return socket;
}

// Функция для настройки обработчиков событий сокета
function setupSocketEventHandlers() {
  if (!socket) return;
  
  // Время последней попытки переподключения
  let lastReconnectAttempt = 0;

  socket.on('connect', () => {
    console.log('Connected to Socket.IO server');
    
    // Отправляем информацию о том, что мы находимся в админке
    if (isBrowser && window.location.pathname.includes('/admin')) {
      socket.emit('admin_connected', { page: window.location.pathname });
    }
  });

  socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
    
    // Автоматическое переподключение с интервалом
    const now = Date.now();
    if (now - lastReconnectAttempt > 10000) { // Не чаще раз в 10 секунд
      lastReconnectAttempt = now;
      console.log('Attempting to reconnect to socket server...');
      socket.connect();
    }
    
    // Проверяем, связана ли ошибка с CSRF
    if (error.message && (error.message.includes('CSRF') || error.message.includes('403'))) {
      console.warn('Возможная проблема с CSRF токеном. Обновление страницы может помочь.');
    }
  });

  // Обработчик нового заказа
  socket.on('newOrder', (newOrder) => {
    console.log('New order received:', newOrder);
    
    // Обработка товаров в заказе для обновления количества на складе
    if (newOrder && newOrder.items) {
      try {
        const items = typeof newOrder.items === 'string' 
          ? JSON.parse(newOrder.items) 
          : newOrder.items;
          
        if (Array.isArray(items)) {
          items.forEach(item => {
            if (item.productId && typeof item.quantity !== 'undefined') {
              // Обновляем запас товаров
              updateStockForProduct(item.productId, -item.quantity);
            }
          });
        }
      } catch (e) {
        console.error('Ошибка при обработке товаров из заказа:', e);
      }
    }
  });

  // Обработчик обновления заказа
  socket.on('orderUpdated', (updatedOrder) => {
    console.log('Order updated:', updatedOrder);
    
    // Обновляем запасы, если заказ был отменен
    if (updatedOrder && updatedOrder.status === 'canceled' && updatedOrder.items) {
      try {
        const items = typeof updatedOrder.items === 'string' 
          ? JSON.parse(updatedOrder.items) 
          : updatedOrder.items;
          
        if (Array.isArray(items)) {
          items.forEach(item => {
            if (item.productId && typeof item.quantity !== 'undefined') {
              // Возвращаем товары на склад при отмене заказа
              updateStockForProduct(item.productId, item.quantity);
            }
          });
        }
      } catch (e) {
        console.error('Ошибка при обработке отмены заказа:', e);
      }
    }
  });

  // Когда товар закончился на складе
  socket.on('productOutOfStock', (product) => {
    console.log('Товар закончился на складе:', product);
    
    // Обновляем статус товара в хранилище
    if (product && product.id) {
      updateProductStock(product.id, 0);
      
      // Используем новую систему уведомлений
      notifyGlobally(`Товар "${product.name_ru}" закончился на складе`, 'out_of_stock');
    }
  });

  // Когда товар снова появляется в наличии
  socket.on('productBackInStock', (product) => {
    console.log('Товар снова в наличии:', product);
    
    // Обновляем статус товара в хранилище
    if (product && product.id && typeof product.stock_quantity !== 'undefined') {
      updateProductStock(product.id, product.stock_quantity);
      
      // Используем новую систему уведомлений
      notifyGlobally(`Товар "${product.name_ru}" снова в наличии`, 'back_in_stock');
    }
  });

  // Обработчик обновления количества товара
  socket.on('productStockUpdated', (product) => {
    console.log('Обновление запасов товара в реальном времени:', product);
    
    // Обновляем количество товара в хранилище
    if (product && product.id && typeof product.stock_quantity !== 'undefined') {
      // Очищаем потенциальные устаревшие данные для этого товара
      const currentState = updateProductStock.getState?.() || {};
      const cleanedStockUpdates = { ...currentState.stockUpdates };
      delete cleanedStockUpdates[product.id];
      
      // Обновляем с новыми данными
      updateProductStock(product.id, product.stock_quantity, product.timestamp || Date.now());
      
      // Показываем уведомление в админке с чётким указанием текущего количества
      if (isBrowser && window.location.pathname.includes('/admin')) {
        notifyGlobally(
          `Обновлены запасы в реальном времени: "${product.name_ru}" - ${product.stock_quantity} ед.`, 
          'update'
        );
      }
    }
  });
  
  // Усиленный мониторинг и восстановление соединения
  socket.on('disconnect', (reason) => {
    console.warn(`Socket.IO отключен: ${reason}`);
    
    // Автоматически пытаемся восстановить соединение при непредвиденных отключениях
    if (reason === 'io server disconnect' || reason === 'transport close') {
      console.log('Пытаемся восстановить соединение...');
      setTimeout(() => {
        socket.connect();
      }, 1000);
    }
  });
}

// Вспомогательная функция для обновления запасов товара
function updateStockForProduct(productId, quantityChange) {
  try {
    // Получаем текущее количество из хранилища или API
    const currentStock = updateProductStock.getState?.getProductStock?.(productId) || 0;
    
    // Вычисляем новое количество (не меньше 0)
    const newStockQuantity = Math.max(0, currentStock + quantityChange);
    
    // Обновляем запас товара
    updateProductStock(productId, newStockQuantity);
    
    console.log(`Socket: Товар #${productId} изменен на ${quantityChange}. Новое количество: ${newStockQuantity}`);
  } catch (e) {
    console.error(`Ошибка при обновлении запасов товара #${productId}:`, e);
  }
}

// Запрашиваем разрешение на нативные уведомления
function requestNotificationPermission() {
  if (isBrowser && 'Notification' in window && Notification.permission !== 'granted') {
    Notification.requestPermission();
  }
}

// Запрашиваем разрешение при загрузке скрипта
requestNotificationPermission();

// Инициализируем сокет при импорте
initializeSocket();

// Экспортируем socket для использования в других компонентах
export default socket;